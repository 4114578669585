<template>
  <section class="about">
    <img src="../assets/textural3.jpg" alt="Experimental Music Festival" />
    <div>
      <h1>{{ c.mainTitle }}</h1>
      <h2>{{ c.title }}</h2>
      <p>{{ c.p1 }}</p>
      <p>{{ c.p2 }}</p>
      <p>{{ c.p3 }}</p>
      <img src="../assets/textural1.jpg" alt="Freeimprovised Music Festival" />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('about'))
</script>

<style lang="scss">
  .about {
    @media only screen and (min-width: 0) {
      padding-top: 24px;

      img {
        width: 100%;
        margin: 0;
      }

      > div {
        display: flex;
        flex-direction: column;

        h1 {
          max-width: 200px;
          margin: 30px 0 0 0;
          align-self: center;
          text-align: center;
          text-transform: uppercase;
        }

        img {
          display: none;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        h1 {
          max-width: 240px;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        h1 {
          max-width: 300px;
        }

        p {
          display: none;
        }

        img {
          width: 100%;
          margin-top: 40px;
          display: flex;
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      > div {
        h1 {
          max-width: 400px;
        }
      }
    }
  }
</style>