export const content = {
  homePage: {
    about: {
      mainTitle: 'Textural Music Festival',
      title: ' Despre Textural',
      p1: 'Festivalul TEXTURAL a fost inițiat de doi muziceni de nișă din București, Andrei Bălan și Călin Torsan. Primul este și sculptor, iar celălat este și scriitor. Ideea lor a fost crearea unui spațiu de manifestare pentru muzicieni similari, cu preocupări constante pentru experimentul sonor.',
      p2: 'Prima ediție TEXTURAL a fost găzduită de Muzeul-Atelier Școala din Piscu, județul Ilfov. Evenimentul s-a desfășurat în intervalul 18 - 19 noiembrie 2022 și a reunit nouă muzicieni. Cu aceeași ocazie s-a impus un detaliu specific festivalului: tragerea la sorți a formulelor instrumentale din fiecare seară.',
      p3: 'Următoarele două ediții, cea din 2023 și spring TEXTURAL 2024, au fost găzduite de Zadar Bar, un bar bucureștean dedicat muzicilor experimentale și celor liber improvizate.',
    },
    zadar: {
      title: 'Zadar Bar',
      p1: 'Două ediții ale festivalului TEXTURAL au fost găzduite de Zadar Bar: cea de la sfârșitul anului 2023 și springTEXTURAL, o ediție specială de an bisect (2024). Reconfigurat pe structura mai veche a fostului Geo Bar, locul este visul și realizarea a doi melomani împătimiți de zona muzicilor neconvenționale: Victor Hariton și Vali Neagu.',
      p2: 'Tot ce înseamnă muzică Zadar, adică scena și ambianța din timpul săptămânii, are legătură cu experimentul, cu ineditul, cu improvizația și cu un spirit care propune libertatea de exprimare și toleranța.',
      p3: 'Zadar Bar se află pe strada Mircea Vulcănescu din București. La numărul 23. Concertele se țin înăuntru, maxim până la ora 22:00, dar există și o curte cu destule mese, unde muzica se poate asculta prin intermediul unei instalații de sunet. Barul a devenit principalul loc de întâlnire a boemei bucureștene, dar și un soi de kilometru zero al artiștilor de nișă. Un fapt inedit: chiar și unul dintre barmani este un artist hip-hop și rap foarte apreciat.',
      p4: 'În sezonul rece concertele se țin lanț, ritmul scăzând în timpul verii, iar foarte mulți artiști străini aflați în București caută să își aranjeze un show în acest loc.',
      p5: 'Pe 24 august 2023, Zadar Bar a fost distins cu trofeul &quot;Jurjac Enescu&quot;, o statuetă care poate fi văzută pe unul dintre rafturile barului și care a punctat rolul lui de deschizător al noilor drumuri muzicale din România.',
    },
    texturalArtists: {
      title: 'Textural Artists',
      artists: [
        {
          name: 'Alexandru Hegyesi',
          about: 'Alexandru Hegyesi este un multi-instrumentist autodidact timișorean. Cântă la țambal, kantele, dulcimer, țiteră transilvană &amp; bavareză etc. A început muzica în 1992, la chitară bas și percuție.',
          image: './images/1.jpg',
          link: 'https://www.youtube.com/watch?v=9hwfwTPwEYA&amp;t=950s',
        },
        {
          name: 'Andrei Bălan',
          about: 'Andrei Bălan este unul dintre inițiatorii Festivalului TEXTURAL. Deopotrivă sculptor și muzician, el improvizează cu chitara și cu electronicele, creând peisaje cinematice de sunet, drone, densități și forme sonore strălucitoare. Este inventatorul unui instrument muzical sculptural care îi poartă numele: Bălanofon.',
          image: './images/2.jpg',
          link: 'https://www.youtube.com/watch?v=xelvrCh2Fec',
        },
        {
          name: ' Aram Shelton',
          about: 'Aram Shelton is an improviser and composer who performs on saxophone, clarinets and live electronics. With a distinct, harmonically rich sound, he develops musical thoughts through constant motion, clear melodic and rhythmic constructs, tonal shifts and complex sounds. His vocabulary utilizes a wide range of extended techniques including multiphonics and harmonics, circular breathing, wind and an exploration of the outer ranges of the instrument.Shelton has been a member of the creative music communities in the San Francisco Bay Area, Chicago, Copenhagen and Budapest. His playing and music appears on more than two dozen albums on labels including Clean Feed, Delmark, Not Two, Astral Spirits and his own Singlespeed Music.',
          image: './images/3.jpg',
          link: 'https://downtownmusic.net/aram-shelton',
        },
        {
          name: 'Bogdan Frigioiu',
          about: `Chitarist și vocalist gutural, cunoscut sub mai multe pseudonime ca Jay sau Spurcăciune, pentru proiectele Rotheads, Void Forger, Cold Brats și Keeng Ra'at.`,
          image: './images/4.jpg',
          link: 'https://www.youtube.com/watch?v=wXq-YZz4fLs',
        },
        {
          name: 'Călin Torsan',
          about: 'Unul dintre inițiatorii Festivalului TEXTURAL. Improvizează la instrumente de suflat, în diferite formule de expresie și orientare artistică. A publicat mai multe cărți de literatură.',
          image: './images/5.jpeg',
          link: 'https://www.youtube.com/watch?v=RTwoIIl7r4k',
        },
        {
          name: 'Cătălin Medoia',
          about: 'Trecând foarte timpuriu de la inginer la antreprenor, și apoi la om de marketing, Cătălin Medoia a fost mereu activ și în zona muzicală, fiind printre altele: organizator de concerte și festivaluri de rock, gazda multor seri de jam-session și membru în juriile unor concursuri de formații rock. Dintotdeauna pasionat de muzică, Medoia a fost și este un admirator și promotor al cosmosului sonor (și nu numai) al lui Frank Zappa.',
          about2: 'Adoră neconvenționalul, unicitatea happeningului, experimentul și muzica improvizată. Medoia nu se consideră un chitarist, ci o persoană care se folosește de chitara electrică pentru a- și exprima spontan gândurile muzicale.',
          image: './images/6.jpg',
          link: 'https://soundcloud.com/neoncola/agree-to-disagree-live-mixtape-time-is-an-agreed-upon-construct-part-1-27-apr-zadar?ref=clipboard&amp;p=a&amp;c=0&amp;si=227d474ad9f244f38d98d68b22dcb3a3&amp;utm_source=clipboard&amp;utm_medium=text&amp;utm_campaign=social_sharing',
        },
        {
          name: 'Irinel Anghel',
          about: 'Compozitoare, performer vocal, artist interdisciplinar (n. București, 1969). A absolvit cursurile Liceului de Muzică „George Enescu” din București, secţia pian (1988) şi pe cele ale Universităţii Naționale de Muzică din București, secţia Muzicologie (1994) şi secţia Compoziție (1996). Master în Muzicologie (1995) şi Compoziţie (1997), Irinel Anghel este doctor în ştiinţe muzicale din 2003 și absolventă a programului postdoctoral (MIDAS) în cadrul UNMB (2013).',
          about2: 'Creaţia ei muzicală este compusă din lucrări instrumentale, muzică de cameră, electronică, lucrări simfonice şi muzică de teatru. În ultimul timp, activitatea ei este dedicată muzicii improvizate, evenimentelor interdisciplinare, hibride, de fuziune si performance art-ului. Este adepta artei experiențiale cu detente suprarealiste.',
          image: './images/7.jpg',
          link: 'https://www.youtube.com/watch?v=I5ntKszoTgU',
        },
        {
          name: 'Iulian Dima',
          about: 'M-am apucat de chitară la 16 ani, iar de atunci muzica a fost ca o călătorie plină de aventuri și neprevăzut, de dezamăgiri crunte, dar și de momente magice pe care nu ți le poate oferi nici o altă formă de artă. Muzica mi-a oferit șansa de a cunoaște oameni talentati, cu povești grozave. Nu regret nimic, aș lua-o oricând de la capăt, ba chiar aș recomanda tuturor părinților să-și trimită copiii să învețe un instrument muzical, oricare ar fi el. Muzica este o experiență ce îți deschide mintea, îți alină sufletul și îți dezlănțuie imaginația și creativitatea.',
          image: './images/8.jpeg',
          link: 'https://www.youtube.com/watch?v=tss6ZZPbWHE',
        },
        {
          name: 'Juan Carlos Negretti',
          about: 'Având muzica și arhitectura în centrul preocupărilor sale și acumulând peste 20 ani de experiență ca percuționist și baterist, Juan Carlos poate fi descris ca un arhitect al ritmurilor. În stilul său de interpretare, ludic și eclectic, ascultătorul va putea remarca influențele native afro-caraibe, care se contopesc cu cele balcanice și orientale, împreună cu deschiderea către zona experimentală.',
          image: './images/9.jpg',
          link: 'https://www.youtube.com/watch?v=nJjxr-SGPao',
        },
        {
          name: 'Marius Achim',
          about: 'A început activitatea artistică în 1987, ca toboșar în trupa liceului. După 1989, a colaborat cu diferite proiecte muzicale de country, rock, hard rock, metal, jazz și improvizație liberă.',
          image: './images/10.jpg',
          link: 'https://www.youtube.com/watch?v=CdAmzepCyPw',
        },
        {
          name: 'Mihai Iordache',
          about: `Saxofonist, compozitor. <br><br>
            1987 - debut la Festivalul de jazz Costinești, la blockflöte. <br>
            1987-1989 - lecții de improvizație cu pianistul <b>Marius Popp</b>. <br>
            1990 - primul saxofon decent, primul grup de jazz cu <b>Pedro Negrescu</b> - contrabas, <b>Lucian Maxim</b> - baterie. Lecții cu saxofonistul <b>Garbis Dedeian</b>. <br>
            1993-1999 - încercare de a străpunge blocada muzicii pop-rock alături de formația <b>Sarmalele Reci</b>. Albume: 1995, 1996, 1999. <br>
            1999-2003 - angajamente alimentare. <br>
            1999 - albumul Axis Mundi, cu <b>Harry Tavitian</b> și Orient Express. <br>
            2003 - primul album propriu de jazz: Friday, featuring <b>Tom Smith</b> <br>
            2003 - cooptat în formația <b>Kumm</b>. <br>
            2019 - cooptat în <b>Ploiești Big Band Jazz Society</b>, la saxofon bariton <br>
            2004, 2005, 2012, 2013, 2014, 2019, 2022 - albume proprii de jazz. <br>
            2004, 2006, 2009, 2015 - albume alături de <b>Kumm</b>.
          `,
          image: './images/11.jpg',
          link: 'https://www.youtube.com/watch?v=UApvn5poBLM',
        },
        {
          name: 'Teodor Horea',
          about: 'Sub pseudonimul Neon Cola, Teodor Horea este compozitor de muzică de film, muzică electronică, electroacustică și spectrală, sintezist și improvizator live de free jazz și progressive rock la pian și sintetizatoare, sound designer și producător în nișa muzicii experimentale, demarând în prezent constituirea unui studio de înregistrări dedicat.',
          image: './images/12.jpg',
          link: 'https://soundcloud.com/neoncola/agree-to-disagree-live-mixtape-time-is-an-agreed-upon-construct-part-1-27-apr-zadar?ref=clipboard&amp;p=a&amp;c=1&amp;si=40f9fb1c6100499ea1ac9f295cf70477&amp;utm_source=clipboard&amp;utm_medium=text&amp;utm_campaign=social_sharing',
        },
        {
          name: 'Sian Brie',
          about: 'După ce studiază la Conservatorul din București, unde este inițiat în jazz și free jazz de pianistul Mircea Tiberian, Sian Brie se mută la Berlin. Aici are ocazia să cunoască și să colaboreze cu importanți muzicieni de pe scena berlineză, cum ar fi Christian Lillinger, Horst Nonnenmacher sau Manuel Miethe. După un an la Berlin, Sian Brie pleacă la Londra, unde îl cunoaște pe trompetistul polonez Dawid Frydryk. Cei doi descoperă că bariera lingvistică poate fi depășită prin vocabularul comun al experienței de expat venit din fostul bloc comunist. Muzicienii întâmpină atât discriminare negativă, cât și pozitivă și în final conștientizează faptul că au ajuns să aibă un comportament în perfectă conformitate cu eticheta care le-a fost pusă, de aici luând naștere atitudinea exprimată prin fraza “to live up to your discrimination standards”. Sian Brie și Dawid Frydryk formează un trio de muzică improvizată alături de Guillaume Viltard. Totodată, Sian Brie este fondator al grupului de avangardă UMAMI, care îi include pe John Edwards, Mark Sanders și Dawid Frydryk, și este membru al formației de muzică experimentală din București, And then This!. Printre alți muzicieni cu care colaborează se numără Mircea Tiberian, Maurice de Martin, Michael Griener, Mihai Iordache etc.',
          image: './images/13.jpg',
          link: 'https://www.youtube.com/watch?v=v53WP11-RwI',
        },
        {
          name: 'Sorin Romanescu',
          about: 'Debutul său ca muzician de jazz a avut loc la începutul anilor ‘90. După o scurtă activitate concertistică, în urma absolvirii unui curs de chitară clasică la Școala Populară de Artă din București și după absolvirea cursului de improvizație al pianistului Marius Popp, de la aceeași școală, a decis să-și dedice activitatea, în primul rând, muzicii de jazz și improvizației. De-a lungul carierei a cântat cu muzicieni din toate generațiile, de la pianistul Marius Popp și saxofonistul Garbis Dedeian (colaborarea cu acesta l-a consacrat ca muzician de jazz), până la Luiza Zan, Maria Răducanu, Mihai Iordache, Vlad Popescu, Cristian Soleanu și mulți alții. Manifestând flexibilitate stilistică și având o mare pasiune pentru improvizație, a avut la începutul anilor 2000 colaborări cu artiști coregrafi din zona dansului contemporan, precum Vava Ștefănescu, Eduard Gabia sau Mihai Mihalcea. De o bună perioadă de timp concertează și înregistrează pe albumele lui Alexandru Andrieș. A fost producător audio pentru muzică de publicitate, a realizat câteva coloane sonore de film documentar și coloana sonoră a filmului Câini.',
          image: './images/14.jpeg',
          link: 'https://www.youtube.com/watch?app=desktop&amp;v=c-cRbkUmPv4&amp;t=194s',
        },
      ],
    },
    festival: {
      title: 'Experimental-Freeimprovised Bucharest Music Festival',
      p1: 'Ați vrea să definiți arta muzicală a viitorului? :”Ea sfidează orice definiție. Aș putea să o descriu ca un sistem puternic de aeronautică spirituală, menită să înalțe sufletul până la regiunile sublime ale armoniei supersenzoriale, deasupra constrângerilor telurice grosiere ale formelor prestabilite în compoziție și dincolo de atracțiile vulgare ale melodiei susținute”.un comentariu al d nei Joseph Bennet, în revista londoneză Musical Times din 1877- - Viețile Marilor Compozitori - Harold C. Schonberg pg.271 ed. Lider 1970',
      p2: 'John Cage nu s-a consumat pentru ca de fapt, lui încă nu i-a venit timpul. El a anunțat o nouă paradigmă artistică ce urmează să apară. John Cage a aruncat buzduganul pentru a pregăti apariția unui nou mod de a crea care se ciocneste puternic de vechile modele. A fost un deschizător de drumuri pe termen lung, un „dătător de idei”, un transformator al viziunii asupra artei și muzicii, un caz controversat care încă pune probleme de acceptare (pornind chiar de la afirmația: Everything we do is music). Conceptele legate de numele său sunt: nedeterminare, aleatorism, „chance music”, liniște plină (celebra piesă 4’33” fiind o lecție de ascultare activă), performance art, noise music, grafism, muzică electroacustică, live electronics, instrumente alternative.',
      p3: 'Un asemenea salt în percepție este mai întâi vestit de un vizionar, pentru ca mai apoi, să se manifeste, să fie acceptat și recunoscut. La început, acel vestitor va fi blamat, ucis prin ignorare fățisă, ocolit. Arta-experiență, creația vie, hibridizarea ce desființează granițe sunt încă în stare născândă, dacă privești lucrurile dintr-un punct mai înalt, care îți permite să ai o perspectivă mai largă asupra evoluției lucrurilor.',
      p4: 'Ori de cite ori s-a încercat concilierea inteligenței cu sensibilitatea, a subzistat numai inteligența și astfel s-a ajuns la uscăciune. Este neapărat nevoie ca mâna artistului să înainteze în necunoscut, ea trebuie să păstreze sentimentul pericolelor ce o pândesc, să simtă prăpastia. Imaginați-vă o mână care calchiază: știe exact unde merge; si pentru că știe drumul, este lipsită de viață. Trebuie să se întîmple exact contrariul, ductul trebuie să fie viu, si atunci este ireversibil.',
    },
    contact: {
      title: 'Contact',
      p: `Ne puteți contacta la adresa: <b>andreibalan.art@gmail.com</b>`,
      p2: 'Suntem și pe facebook:'
    }
  }
}