<template>
  <div class="main-header">
    <img src="../assets/logo.png" alt="Textural music festival" />
  </div>
</template>

<style lang="scss">
  @import '../styles/vars.scss';

  .main-header {
    @media only screen and (min-width: 0) {
      padding: 6px 0;
      border-bottom: 1px solid $text-color;
      img {
        margin-left: 24px;
        height: 30px;
      }
    }

    @media only screen and (min-width: 768px) {
      padding: 10px 0;
      img {
        margin-left: 24px;
        height: 40px;
      }
    }

    @media only screen and (min-width: 1024px) {
    }
  }
</style>