<template>
  <section class="zadar">
    <div>
      <h2>{{ c.title }}</h2>
      <p>{{ c.p1 }}</p>
      <p>{{ c.p2 }}</p>
      <p>{{ c.p3 }}</p>
      <p>{{ c.p4 }}</p>
      <p>{{ c.p5 }}</p>
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('zadar'))
</script>

<style lang="scss">
  .zadar {
    @media only screen and (min-width: 0) {}

    @media only screen and (min-width: 768px) {}

    @media only screen and (min-width: 1024px) {}
  }
</style>