import { createStore } from 'vuex'
import { content } from './content'

export default createStore({
  state: {
    page: 'homePage',
  },
  getters: {
    sectionContent: (state) => (section) => content[state.page][section],
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
