<template>
  <section class="festival">
    <div>
      <h2>{{ c.title }}</h2>
      <p>{{ c.p1 }}</p>
      <p>{{ c.p2 }}</p>
      <p>{{ c.p3 }}</p>
      <p>{{ c.p4 }}</p>
      <img src="../assets/textural2.jpg" alt="Experimental-Freeimprovised Bucharest Music Festival" />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('festival'))
</script>

<style lang="scss">
  .festival {
    @media only screen and (min-width: 0) {
      > div {
        img {
          display: none;
        }
      }
    }

    @media only screen and (min-width: 768px) {}

    @media only screen and (min-width: 1024px) {
      > div {
        p {
          display: none;
        }

        img {
          width: 100%;
          margin-top: 40px;
          display: flex;
        }
      }
    }
  }
</style>