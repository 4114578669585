<template>
  <section class="contact">
    <h2>{{ c.title }}</h2>
    <p v-html="c.p" />
    <div>
      <p>{{ c.p2 }}</p>
      <a href="https://www.facebook.com/profile.php?id=100089330628610" target="_blank">
        <img src="../assets/fb.png" alt="Textural festival" />
      </a>
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('contact'))
</script>

<style lang="scss">
  .contact {
    @media only screen and (min-width: 0) {
      > div {
        margin-top: 20px;
        display: flex;
        align-items: center;

        p {
          margin: 0;
        }

        img {
          width: 40px;
          height: 40px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    @media only screen and (min-width: 768px) {}

    @media only screen and (min-width: 1440px) {}
  }
</style>