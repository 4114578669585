<template>
  <section class="artists">
    <div>
      <h2>{{ c.title }}</h2>
      <div>
        <ArtistsItem
            v-for="artist in c.artists"
            :key="artist.name"
            :name="artist.name"
            :about="artist.about"
            :about2="artist.about2"
            :image="artist.image"
            :link="artist.link"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
  import ArtistsItem from '@/components/ArtistsItem'
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('texturalArtists'))
</script>

<style lang="scss">
  .artists {
    @media only screen and (min-width: 0) {

      > div {
        div {
          display: flex;
          flex-direction: column;

          h3 {
            align-self: start;
          }

          img {
            align-self: center;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {

    }

    @media only screen and (min-width: 1024px) {}

    @media only screen and (min-width: 1440px) {

    }
  }
</style>
